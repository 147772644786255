import { Options } from "vue-class-component";
import { useToast } from "vue-toastification";
const toast = useToast();
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import BalanceDataTableItem from "@/entity/attendance/duty/BalanceDataTableItem";
import API from "@/api/attendance/duty/balanceData";
import { ElMessageBox } from "element-plus";
import BatchAPI from "@/api/attendance/duty/batch";

@Options({
    name: "app-attendance-duty-balanceData",
    components: {},
})
export default class BalanceData extends BaseTableMixins<BalanceDataTableItem> implements Partial<BaseTableInterface<BalanceDataTableItem>> {
    private dialogVisible = false;
    private scenarioBox = true;
    private uploadUrl = "/api/v1/attendance/duty/balanceData/formUpload";
    private excelFile: any = {};
    private errorMessage: any = {};
    protected batchState: any = {};

    public created(): void {
        this.setInterface(this);
        this.editTableItem = new BalanceDataTableItem();
        this.editModalItem = new BalanceDataTableItem();
    }

    public activated(): void {
        this.getList(1);
        this.getBatch();
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //获取批次状态
    protected getBatch() {
        BatchAPI.getBatchOne({})
            .then((res) => {
                this.batchState = res.data.records.batchState;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new BalanceDataTableItem();
        this.editModalItem = new BalanceDataTableItem();
        this.editModalItem.dutyStatus = 1;
        this.editModalTitle = "添加新数据";
        this.editModalSubmitButtonName = "添加";
    }

    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.userCode)) return "工号不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.userName)) return "姓名不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyHour)) return "时数不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyDate)) return "值班日期不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyStart)) return "值班开始不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyEnd)) return "值班结束不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyReason)) return "备注不能为空！";
        return null;
    }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改数据";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.userCode)) return "工号不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.userName)) return "姓名不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyHour)) return "时数不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyDate)) return "值班日期不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyStart)) return "值班开始不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyEnd)) return "值班结束不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dutyReason)) return "备注不能为空！";
        return null;
    }

    //
    private btnBasicDel(item: BalanceDataTableItem) {
        const dutyId = item.dutyId;
        ElMessageBox.confirm("是否要删除这数据", "注意", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                this.isLoading = true;
                API.basicDel({ dutyId })
                    .then((response) => {
                        //console.log(response.data);
                        this.toast.success("删除成功");
                        this.getList(0);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            })
            .catch(() => {});
    }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除数据",
            message: "姓名: " + this.editModalItem.userName + "<br>请确认删除？",
        };
    }

    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.dutyId)) return "假期数据ID不能为空！";
        return null;
    }

    //监听文件上传
    private onFileChange(uploadFile: any): void {
        this.excelFile = uploadFile;
    }

    private handleClose = (done: () => void) => {
        ElMessageBox.confirm("确定关闭窗口吗?")
            .then(() => {
                done();
            })
            .catch(() => {
                // catch error
            });
    };

    //判断文件大小
    public beforeUpload(file: File) {
        const isLt500Kb = file.size / 1024 <= 500;
        if (!isLt500Kb) {
            toast.error("上传文件大小不能超过500KB");
        }
        return isLt500Kb;
    }

    //上传Excel
    private async submitUpload() {
        if (this.$tools.isEmpty(this.excelFile)) {
            this.toast.error("请先选择文件");
            return;
        }
        this.isLoading = true;
        const formData = new FormData();
        formData.append("file", this.excelFile.raw);
        formData.append("name", this.excelFile.name);
        formData.append("size", this.excelFile.size);
        formData.append("save", "false");
        try {
            const res = await API.formUpload(formData);
            console.log(res.data.errorMessage);
            this.dialogVisible = true;
            this.isLoading = false;

            // 处理后端传来的数据
            const errors: string[] = [];
            for (const key in res.data.errorMessage) {
                if (res.data.errorMessage.hasOwnProperty(key)) {
                    const errorMsg = res.data.errorMessage[key];
                    const errorLines = errorMsg.split("|");
                    errorLines.forEach((line: any) => {
                        errors.push(line);
                    });
                }
            }

            if (errors.length > 0) {
                this.showErrorsDialog(errors);
            } else {
                const formData = new FormData();
                formData.append("file", this.excelFile.raw);
                formData.append("name", this.excelFile.name);
                formData.append("size", this.excelFile.size);
                formData.append("save", "true");
                API.formUpload(formData)
                    .then(() => {
                        this.toast.success("上传成功");
                        this.getList(0);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        } catch (error) {
            this.isLoading = false;
            toast.error("上传失败");
        }
    }

    //
    public showErrorsDialog(errors: any) {
        this.dialogVisible = false;
        console.log(errors);
        ElMessageBox.confirm(`<p>${errors.join("</p><p>")}</p>`, "错误提示", {
            customStyle: { maxWidth: "50rem" },
            dangerouslyUseHTMLString: true,
            confirmButtonText: "确认",
            cancelButtonText: "取消",
        }).then(() => {
            const formData = new FormData();
            formData.append("file", this.excelFile.raw);
            formData.append("name", this.excelFile.name);
            formData.append("size", this.excelFile.size);
            formData.append("save", "true");
            API.formUpload(formData)
                .then(() => {
                    this.toast.success("上传成功");
                    this.getList(0);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    //显示对话框
    private handleCancel() {
        this.dialogVisible = false;
    }

    //改写日期
    public formatDate(date: string): string {
        return date.split(" ")[0];
    }

    //改写时间
    public getTime(time: string): string {
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        // const seconds = date.getSeconds().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    }

    //审批提交按钮
    public btnSubmissionBatch() {
        this.isLoading = true;
        API.submissionBatch({ status: 1 })
            .then(() => {
                toast.success("审批提交成功");
                if (this.batchState === 40) {
                    this.batchState = 50;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    //通过审批按钮
    public handleAgree() {
        this.isLoading = true;
        API.auditBatch({ audit: 1 })
            .then(() => {
                toast.success("审批通过");
                if (this.batchState === 50) {
                    this.batchState = 60;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    //驳回审批按钮
    public handleReject() {
        this.isLoading = true;
        API.auditBatch({ audit: 2 })
            .then(() => {
                toast.error("审批驳回");
                if (this.batchState === 50) {
                    this.batchState = 40;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "值班状态",
            message: "请确认修改"+ this.editModalItem.userName +"的假期数据状态？",
            submitButtonName: this.editModalItem.dutyStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.dutyStatus = this.editModalItem.dutyStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.dutyId)) return "批次ID不能为空！";
        return null;
    }

    //显示对话框
    private scenarioBoxCancel() {
        this.scenarioBox = false;
    }

    //一键禁用按钮
    public disableAll(item: String) {
        const userCode = item;
        ElMessageBox.confirm("是否禁用这些数据", "注意", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                this.isLoading = true;
                API.basicDisableAll({ userCode })
                    .then((response) => {
                        //console.log(response.data);
                        this.toast.success("禁用成功");
                        this.getList(0);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            })
            .catch(() => {});
    }
}
